// Translated
// Migrated
<template>
  <div class="w-full sticky-top mx-auto header-nav__bg px-0">
    <div
      v-if="alert.show && !sideMenuActive && !alertHidden"
      class="flex flex-wrap -mx-4 global-alert"
      :class="{ 'scrolling-header': scrolling }"
    >
      <div class="xl:w-10/12 px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div
            class="w-12/12 text-align-center"
            v-html="alert.text"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -mx-4 container-content mx-auto relative">
      <div class="3xl:w-10/12 w-full px-4 mx-auto static">
        <div class="flex flex-wrap">
          <nav
            class="flex items-center relative header-nav flex-nowrap"
            style="z-index: 10"
            :class="`header--${$isSol ? 'solresor' : 'rolfs'}`"
          >
            <div
              class="flex items-center"
              @click="closeSideMenuActive"
            >
              <nuxt-link
                class="navbar-brand pl-0"
                :to="getWhitelabel?.referrerUrl"
                external
              >
                <img
                  id="sitelogo"
                  :src="logoImg"
                  alt="logo"
                  :height="$isSol ? 80 : 42"
                  :width="$isSol ? 192 : 340"
                />
              </nuxt-link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { faUser } from '@fortawesome/pro-duotone-svg-icons'
import scrollingMixin from '@layers/web/mixins/scrolling.mixin.js'
import events from '@layers/web/constants/events.js'

const readyStateComplete = 'complete'

export default defineNuxtComponent({
  mixins: [scrollingMixin],

  emits: [events.pageIsReady],

  data () {
    return {
      icons: {
        faUser,
      },

      showSearchform: false,
      searchPhrase: '',
      activeNav: '',
      previousScrollY: 0,
      isAppleSafari: false,
      timer: null,
    }
  },

  computed: {
    ...mapState(useLocaleStore, [
      'canLocaleLogin',
    ]),

    ...mapState(useRootStore, {
      showAgentCalls: 'shouldShowAgentCalls',
      topNavbar: 'topNavbar',
      alert: 'alert',
    }),

    ...mapState(useMenuStore, {
      sideMenuActive: 'sideMenuActive',
      alertHidden: 'alertHidden',
    }),

    ...mapState(useWhitelabelStore, {
      whitelabel: 'whitelabel',
      getWhitelabel: 'getWhitelabel',
    }),

    logoImg () {
      if (!this.getWhitelabel?.logo) {
        return ''
      }
      return formatCloudinaryImagePath(this.$t(this.getWhitelabel.logo), {
        transformation: { custom: `w_${this.logoSize.width * 2}` },
        isSol: this.$isSol,
      })
    },

    logoSize () {
      return this.$isSol ? { width: 230, height: 96 } : { width: 340, height: 42 }
    },

    logoSizeMobile () {
      return this.$isSol ? { width: 138, height: 40 } : { width: 196, height: 24 }
    },
  },

  watch: {
    sideMenuActive (v) {
      if (!this.isAppleSafari) {
        return
      }
      if (v) {
        this.previousScrollY = window.scrollY
        window.document.body.classList.add('sideMenuActive')
        window.document.documentElement.classList.add('sideMenuActive')
      } else {
        window.document.body.classList.remove('sideMenuActive')
        window.document.documentElement.classList.remove('sideMenuActive')
        window.scrollTo(0, this.previousScrollY)
      }
    },
  },

  created () {
    if (process.browser) {
      // eslint-disable-next-line
      window.addEventListener('click', this.clickHandler)
      this.initCookies()
    }
  },

  beforeUnmount () {
    window.removeEventListener('click', this.clickHandler)
    clearTimeout(this.timer)
  },

  mounted () {
    this.isAppleSafari = isIOSSafari

    this.handlePageInit()

    /*
    ** Prevent iOS Safari modal scrolling bug
    ** https://stackoverflow.com/questions/43563795/bootstrap-modal-background-scroll-on-ios/52591624
    */
    if (!this.isAppleSafari) {
      return
    }
    this.$event.$on('bv::modal::show', () => {
      this.previousScrollY = window.scrollY
    })
    this.$event.$on('bv::modal::shown', () => {
      window.document.body.classList.add('sideMenuActive')
      window.document.documentElement.classList.add('sideMenuActive')
    })
    this.$event.$on('bv::modal::hide', () => {
      window.document.body.classList.remove('sideMenuActive')
      window.document.documentElement.classList.remove('sideMenuActive')
    })
    this.$event.$on('bv::modal::hidden', () => {
      window.scrollTo(0, this.previousScrollY)
    })
  },

  methods: {
    ...mapActions(useMenuStore, ['toggleSideMenuActive', 'closeSideMenuActive']),
    ...mapActions(useRootStore, ['handleAgentCookie']),

    clickHandler (e) {
      // wasn't removing listener
      if (!this.$el.contains(e.target)) {
        this.activeNav = ''
      }
    },

    searchBtnHandler () {
      this.showSearchform = !this.showSearchform
    },

    clickNavItemHandle (curNav, navItem) {
      if (navItem.link && !navItem.submenu) {
        return
      }

      if (this.activeNav === curNav) {
        this.activeNav = ''
      } else if (this.activeNav === '') {
        this.activeNav = curNav
      }
    },

    initCookies () {
      if (!this.$isSol) {
        if (this.$router.currentRoute.value?.query?.a) {
          // Seems the SSR setting (nuxtServerInit) isn't entirely reliable.
          // So to ensure no cookie sets faulty, we double check.
          this.handleAgentCookie(this.$router.currentRoute.value?.query?.a)
        }
      }
    },

    handlePageInit () {
      const onReadyState = this.handleReadyState
      document.onreadystatechange = function () {
        onReadyState(document.readyState)
      }

      this.timer = window.setTimeout(() => {
        this.handleReadyState(readyStateComplete)
      }, 10000)
    },

    handleReadyState (state) {
      this.$event.$emit(events.pageIsReady, state)

      if (state === readyStateComplete) {
        window.document.body.classList.add('--loaded')
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.slide-fade {
  &-enter-from,
  &-leave-to {
    transform: translateX(50px);
    opacity: 0;
  }

  &-enter-to,
  &-leave-from {
    transform: translateX(0);
    opacity: 1;
  }

  &-enter-active {
    transition: all 0.3s ease;
  }

  &-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
}
.header-nav #navbarSupportedContent ul li.header-phone.contact-small span {
  font-size: 8px;
}
.header--rolfs {
  .nav-item-search,
  .login-header {
    padding: 0 15px;
  }

  #sitelogo {
    max-width: 340px;

    @media (max-width: 1280px) {
      max-width: 260px;
    }
    @media (max-width: 1080px) {
      max-width: 200px;
    }
  }
}
.header--solresor {
  &.header-nav {
    position: static;

    @media (max-width: theme('screens.3xl')) {
      padding: 0;
    }

    .header-nav-search-btn {
      svg {
        height: 16px;
        width: 16px;
      }
    }

    #navbarSupportedContent ul li {
      .nav-link {
        text-transform: none;
        font-size: .95rem !important;
        padding: 0 17px;
        display: flex;
        align-items: center;

        svg {
          margin-left: 2px;
        }
      }

      @media (max-width: theme('screens.2xl')) {
        .nav-link {
          padding: 0 17px;
        }
      }

      @media (max-width: theme('screens.xl')) {
        .nav-link {
          padding: 0 21px;
          width: min-content;
          white-space: break-spaces;
          text-align: center;
        }
      }

      .identity--solresor & {
        .nav-link {
          // Fix "Utflykter" tab overlapping logo
          @media (max-width: 1464px) {
            padding: 0 12px;
            font-size: 0.85rem !important;
          }
        }
      }
    }
  }
  .flex.items-center.relative {
    .navbar {
      &-brand {
        width: 100%;
        border-top-right-radius: 96px;
        position: absolute;
        left: 0;
        background: theme('colors.white');
        padding: 0 0 0 34px !important;
        height: 100%;
        align-items: center;

        img {
          height: 100%;
        }

        @media (max-width: calc(theme('screens.lg') - 1px)) {
          padding: 0 0 0 20px !important;
        }
      }
      &-collapse {
        position: absolute;
        right: 35px;

        @media (max-width: theme('screens.2xl')) {
          right: 0;
        }
      }
    }
  }
}
.favourites-item {
  border: none !important;
  display: flex;

  .hide {
    display: none !important;
  }
}
</style>
